// src/components/ProtectedRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getToken } from '../services/athService';

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const token = getToken();
        if (token) {
          return (
            <React.Suspense >
              <Component {...props} />
            </React.Suspense>
          );
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
export default ProtectedRoute;
